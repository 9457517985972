import { API_URL } from 'config.js';
import { post } from './helpers';

export const zara_resubmit_shipment = async (payload) => {
    const response = await post(
        `${API_URL}/inbound-shipments/resubmit`,
        payload
    );
    return {
        status: response?.success,
        ...(await response.json()),
    };
};

export const zara_bulk_resubmit_shipment = async (payload) => {
    const response = await post(
        `${API_URL}/inbound-shipments/bulk-resubmit`,
        payload
    );

    if (!response.ok) {
        return {
            status: response.ok,
        };
    }

    return {
        status: response.ok,
        message: await response.text()
    };
};
