import { API_URL } from 'config.js';
import { roles } from '../role_permissions';
import { getPermissionsFromRoles } from '@react-admin/ra-rbac';
import { store } from 'core/redux/store';
import { setSessionData } from 'core/redux/actions/authAction';
import { setSettings } from 'core/redux/actions/generalDataActions';
import {
    define_settings,
    arrays,
} from 'app/views/settings/contexts/SettingsContext';
import { getSettings, get_phone_codes } from 'infra/services/api';

const account_users = [
    'AccountSuperUser',
    'AccountUser',
    'AccountUserNonBilling',
];

async function callAccountSettings(account_id) {
    const response = await getSettings(account_id);
    if (response === false) {
        return;
    }
    const { provider_settings } = response;
    if (Array.isArray(provider_settings)) {
        const json_data = {};

        provider_settings.forEach((element) => {
            let value = '';
            if (arrays.indexOf(element.setting) !== -1) {
                value = JSON.parse(element.value);
            } else {
                value = element.value;
            }

            json_data[element.setting] = value;
        });

        // phone code
        const response = await get_phone_codes();
        const filteredData = response.map((val) => val.code);

        store.dispatch(setSettings({ ...define_settings, ...json_data, phone_codes: filteredData }));
    }
}

const authProvider = {
    login: ({ email, password }) => {
        let url = API_URL + '/auth/login';
        const request = new Request(url, {
            method: 'POST',
            body: JSON.stringify({ email: email, password: password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then((response) => {
                console.log('response authProvider login', response);
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((response) => {
                localStorage.setItem('id', response.user.id);

                localStorage.setItem('username', response.user.username);
                localStorage.setItem('email', response.user.email);
                localStorage.setItem('name', response.user.name);
                localStorage.setItem('user', JSON.stringify(response.user));

                //added this to catch the [object Object] value on user storage [start]
                try {
                    const user = JSON.parse(localStorage.getItem('user'));
                } catch (e) {
                    localStorage.removeItem('user');
                    localStorage.setItem('user', JSON.stringify(response.user));
                }
                //added this to catch the [object Object] value on user storage [end]

                localStorage.setItem('token', response.access_token);
                localStorage.setItem('is-authenticated', 'true');
                localStorage.setItem('avatar', response.avatar);
                localStorage.setItem('role', response.user.role.name);
                localStorage.setItem('is-parcel-agree', false);
                store.dispatch(setSessionData(response.user));

                if (account_users.includes(response.user.role.name)) {
                    console.log('account_users.includes');
                    callAccountSettings(response.user.account_id);
                    localStorage.setItem(
                        'account_id',
                        response.user.account_id
                    );
                } else {
                    console.log('account_id');
                    callAccountSettings(0);
                    localStorage.setItem('account_id', 0);
                }

                return Promise.resolve();
            })
            .catch((error) => {
                if (
                    error.message === 'Failed to fetch' ||
                    error.stack === 'TypeError: Failed to fetch'
                ) {
                    throw new Error('Incorrect credentials');
                }

                throw new Error(error);
            });
    },

    logout: () => {
        localStorage.removeItem('username');
        localStorage.removeItem('id');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('avatar');
        localStorage.removeItem('user');
        localStorage.removeItem('is-authenticated');
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('account_id');
        store.dispatch(setSessionData(null));
        return Promise.resolve();
    },

    checkAuth: () => {
        if (localStorage.getItem('is-authenticated')) {
            try {
                store.dispatch(
                    setSessionData(JSON.parse(localStorage.getItem('user')))
                );
                return Promise.resolve();
            } catch (e) {
                localStorage.removeItem('username');
                localStorage.removeItem('id');
                localStorage.removeItem('name');
                localStorage.removeItem('email');
                localStorage.removeItem('avatar');
                localStorage.removeItem('user');
                localStorage.removeItem('is-authenticated');
                localStorage.removeItem('token');
                localStorage.removeItem('role');
                localStorage.removeItem('account_id');
                store.dispatch(setSessionData(null));
                return Promise.reject({ redirectTo: '/login' });
            }
        }
        return Promise.reject();
    },
    checkError: () => Promise.resolve(),

    getPermissions: () => {
        const role = localStorage.getItem('role');
        const user_role = store.getState().auth.session?.role;
        const global_roles = [
            'AccountUser',
            'AccountUserNonBilling',
            'AccountSuperUser',
            'SuperAdmin',
        ];
        const roles_data = {
            [user_role.name]: [
                ...user_role.permissionsui.map((data) => ({
                    action: ['read', 'create', 'edit', 'list'],
                    resource:
                        data.guard_name === 'assign_tracking_stickers'
                            ? 'tools'
                            : data.guard_name.replace(/_/g, '-'),
                })),
                {
                    action: ['read', 'create', 'edit', 'list'],
                    resource: 'track',
                },
                {
                    action: ['read', 'create', 'edit', 'list'],
                    resource: 'address-books',
                },
                {
                    action: ['read', 'create', 'edit', 'list'],
                    resource: 'my-profile',
                },
            ],
        };
        const mapResourceChecker = () => {
            const userRoleData = user_role;

            const permission_actions =
                userRoleData?.permissions.map((data) => data.name) || [];

            const displayDriverTab =
                permission_actions.includes('View drivers map');

            const displayBranchesTab =
                permission_actions.includes('View branches map');

            const displayRatesTab =
                permission_actions.includes('View rates map');

            const displaySurchargesTab = permission_actions.includes(
                'View surcharges map'
            );

            const displayServiceDaysTab = permission_actions.includes(
                'View service days map'
            );

            const displayDraftTab =
                permission_actions.includes('View draft map');

            if (
                displayDriverTab ||
                displayBranchesTab ||
                displayRatesTab ||
                displaySurchargesTab ||
                displayServiceDaysTab ||
                displayDraftTab
            ) {
                return true;
            } else {
                return false;
            }
        };

        if (mapResourceChecker()) {
            roles_data[user_role.name].push({
                action: ['read', 'create', 'edit', 'list'],
                resource: 'maps',
            });
        }

        if (user_role.name === 'AmazonUsers') {
            roles_data[user_role.name] = [
                {
                    action: ['read', 'create', 'edit', 'list'],
                    resource: 'amazon-shipments',
                },
            ];
        }

        return Promise.resolve(
            getPermissionsFromRoles({
                roleDefinitions: roles_data,
                userRoles: [role],
            })
        );
    },
    getRoles: () => Promise.resolve(roles),
    getIdentity: () =>
        Promise.resolve({
            id: localStorage.getItem('id'),
            username: localStorage.getItem('username'),
            fullName: localStorage.getItem('name'),
            role: localStorage.getItem('role'),
            account_id: localStorage.getItem('account_id'),
            avatar: localStorage.getItem('avatar'),
        }),
};

export default authProvider;
