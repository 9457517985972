import { Box, Icon, IconButton, styled } from '@mui/material';
import { topBarHeight } from 'app/utils/constant';
import React, { Fragment, useState } from 'react';
import { Formik } from 'formik';
import { checkWaybillExist } from 'infra/services/api';
import { useSnackbar } from 'notistack';
import { Count } from 'react-admin';
import { useNavigate } from 'react-router-dom';

const SearchContainer = styled(Box)(({ theme }) => ({
    top: 0,
    left: 0,
    zIndex: 9,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    height: topBarHeight,
    color: theme.palette.text.primary,
    background: theme.palette.primary.main,
    '&::placeholder': { color: theme.palette.text.primary },
}));

const SearchInput = styled('input')(({ theme }) => ({
    width: '100%',
    border: 'none',
    outline: 'none',
    fontSize: '1rem',
    paddingLeft: '20px',
    height: 'calc(100% - 5px)',
    color: theme.palette.text.primary,
    background: theme.palette.primary.main,
    '&::placeholder': { color: theme.palette.text.primary },
}));

const initialValues = {
    short_tracking_reference: '',
};

const MatxSearchBox = () => {
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleFormSubmit = async (data) => {
        const url = 'filter=' + encodeURIComponent(JSON.stringify(data));
        const response = await checkWaybillExist(url);
        if (response && response.length > 0) {
            navigate('/shipments/' + response[0].id);
        } else {
            enqueueSnackbar(
                'It seems that the reference you entered could not be found.',
                { variant: 'error' }
            );
        }
    };

    return (
        <Fragment>
            {!open && (
                <IconButton onClick={toggle}>
                    <Icon sx={{ color: 'text.primary' }}>search</Icon>
                </IconButton>
            )}

            {open && (
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <SearchContainer>
                                <SearchInput
                                    type="text"
                                    label="Search by reference"
                                    placeholder="Search by reference..."
                                    name="short_tracking_reference"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    helperText={
                                        touched.short_tracking_reference &&
                                        errors.short_tracking_reference
                                    }
                                    error={Boolean(
                                        errors.short_tracking_reference &&
                                            touched.short_tracking_reference
                                    )}
                                    autoFocus
                                />
                                <IconButton
                                    onClick={toggle}
                                    sx={{ mx: 2, verticalAlign: 'middle' }}
                                >
                                    <Icon sx={{ color: 'text.primary' }}>
                                        close
                                    </Icon>
                                </IconButton>
                            </SearchContainer>
                        </form>
                    )}
                </Formik>
            )}
        </Fragment>
    );
};

export default MatxSearchBox;
