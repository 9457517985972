import {
    API_URL,
    BASE_URL,
    REACT_APP_WHAT3WORDS_URL,
    REACT_APP_WHAT3WORDS_KEY,
} from 'config.js';
import { post, get, upload, put } from './helpers';

export const what3words = async (words) => {
    try {
        const encodedWords = encodeURIComponent(words);
        const url = `${REACT_APP_WHAT3WORDS_URL}${encodedWords}&key=${REACT_APP_WHAT3WORDS_KEY}`;
        const response = await fetch(url);
        const data = await response.json();
        return {
            data: data,
            status: response.status,
        };
    } catch (error) {
        return error;
    }
};

export const getAvailableLocker = async (terminal) => {
    try {
        const url =
            'https://ksttcgavailability.azurewebsites.net/api/TerminalList?code=d9LkEkIgqdR3WPqzDOFB93Q73uA9n6DtlRFOKsheWYYuAFZgyFPgGg==&terminal=' +
            terminal;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        return {
            data: data,
            status: response.status,
        };
    } catch (error) {
        return error;
    }
};



export const change_locker = async (post_data) => {

    try {
        const response = await post(`${API_URL}/locker-shipments/command`, post_data);
        const data = await response.json();
        return {
            status: response.status,
            data,
        };
    } catch (error) {
        console.error('Error with API request:', error);
        return {
            status: 500,
            data: null,
        };
    }
};

export const change_door = async (post_data) => {

    try {
        const response = await post(`${API_URL}/locker-shipments/command`, post_data);
        const data = await response.json();
        return {
            status: response.status,
            data,
        };
    } catch (error) {
        console.error('Error with API request:', error);
        return {
            status: 500,
            data: null,
        };
    }
};

export const deposit_pending = async (post_data) => {

    try {
        const response = await post(`${API_URL}/locker-shipments/command`, post_data);
        const data = await response.json();
        return {
            status: response.status,
            data,
        };
    } catch (error) {
        console.error('Error with API request:', error);
        return {
            status: 500,
            data: null,
        };
    }
};

export const customer_deposited = async (post_data) => {

    try {
        const response = await post(`${API_URL}/locker-shipments/command`, post_data);
        const data = await response.json();
        return {
            status: response.status,
            data,
        };
    } catch (error) {
        console.error('Error with API request:', error);
        return {
            status: 500,
            data: null,
        };
    }
};

export const courier_collected = async (post_data) => {

    try {
        const response = await post(`${API_URL}/locker-shipments/command`, post_data);
        const data = await response.json();
        return {
            status: response.status,
            data,
        };
    } catch (error) {
        console.error('Error with API request:', error);
        return {
            status: 500,
            data: null,
        };
    }
};

export const change_barcode = async (post_data) => {

    try {
        const response = await post(`${API_URL}/locker-shipments/command`, post_data);
        const data = await response.json();
        return {
            status: response.status,
            data,
        };
    } catch (error) {
        console.error('Error with API request:', error);
        return {
            status: 500,
            data: null,
        };
    }
};

export const getPudoLockers = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
        const response = await get(`${API_URL}/lockers-data`);
        if (response?.status !== 200) {
            return false;
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const notes = async (payload) => {
    const response = await post(`${API_URL}/shipments/notes/bulk`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const bulk_waybills = async (payload) => {
    const response = await post(`${API_URL}/shipments/waybills/bulk`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const bulk_stickers = async (payload) => {
    const response = await post(`${API_URL}/shipments/stickers/bulk`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const download_quote = async (payload) => {
    const response = await post(`${API_URL}/shipments/quote`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};
export const add_transactions = async (payload) => {
    const response = await post(`${API_URL}/billing/transactions`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const bulkEmail = async (payload) => {
    const response = await upload(`${API_URL}/shipments/bulk/email`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const bulkupdate = async (payload) => {
    const response = await post(`${API_URL}/shipments/bulkupdate`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const geocode = async (payload) => {
    const response = await post(`${API_URL}/geocode`, payload);
    return {
        status: response.status,
        data: await response.json(),
    };
};

export const geocodev2 = async (payload) => {
    const response = await post(`${API_URL}/geocodev2`, payload);
    return {
        status: response.status,
        data: await response.json(),
    };
};

export const shipments_create = async (payload) => {
    const f_payload = {
        ...payload,
        requested_from: 'portal',
        platform: "web"
    };
    const response = await post(`${API_URL}/shipments`, f_payload);
    return {
        status: response.status,
        data: await response.json(),
    };
};

export const optin_rates = async (payload) => {
    const f_payload = {
        ...payload,
        requested_from: 'portal',
        platform: "web"
    };
    const response = await post(`${API_URL}/rates/opt-in`, f_payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const rates = async (payload) => {
    const f_payload = {
        ...payload,
        requested_from: 'portal',
        platform: "web"
    };
    const response = await post(`${API_URL}/rates`, f_payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const track = async (params) => {
    const response = await get(
        `${API_URL}/tracking/shipments/public?${params}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const stickers = async (shipment_id) => {
    const token = localStorage.getItem('token');
    const response = await get(
        `${BASE_URL}/generate/sticker/${shipment_id}` + `?api_key=${token}&requested_from=portal`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getPods = async (params) => {
    const response = await get(
        `${API_URL}/shipments/pod/images?shipment_id=${params}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getNotes = async (id) => {
    const response = await get(`${API_URL}/shipments/notes/${id}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getCommunication = async (id) => {
    const response = await get(
        `${API_URL}/shipments/notes/communication/${id}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const uploadPods = async (payload, id) => {
    const response = await upload(
        `${API_URL}/shipments/pod/images?shipment_id=${id}`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const addNotes = async (payload) => {
    const response = await upload(`${API_URL}/shipments/notes`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const _getAccounts = async (name) => {
    const response = await get(
        `${API_URL}/accounts?filter={"status":"Open","search":"${name}"}&range=[0,999]&sort=["time_created","DESC"]`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const sticker = async (shipment_id) => {
    const token = localStorage.getItem('token');
    const response = await get(
        `${API_URL}/generate/sticker/${shipment_id}` + `?api_key=${token}&requested_from=portal`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const editShipmentEvents = async (payload) => {
    const response = await post(`${API_URL}/tracking/shipment-events`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const reversalShipment = async (payload) => {
    const response = await post(
        `${API_URL}/billing/transactions/reversal`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const podDelete = async (payload) => {
    const response = await post(`${API_URL}/shipments/pod/delete`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const generateQR = async (user_id) => {
    const response = await post(`${API_URL}/auth/mfa/qr/${user_id}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const checkWaybillExist = async (params) => {
    const response = await get(`${API_URL}/shipments?${params}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const book_quote = async (payload) => {
    const response = await post(`${API_URL}/shipments/book-quote`, payload);
    return {
        status: response.status,
        data: await response.json(),
    };
};

export const email_help_desk = async (payload) => {
    const response = await post(
        `${API_URL}/notification/special-trip-request`,
        payload
    );
    return {
        status: response.status,
        data: await response.json(),
    };
};

export const resubmitShipment = async (payload) => {
    const response = await post(
        `${API_URL}/shipments/resubmit-shiplogic`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const resolveIntervention = async (payload) => {
    const response = await post(
        `${API_URL}/shipments/update-payment-intervention`,
        payload
    );
    return {
        response_status: response.status,
        ...(await response.json()),
    };
};

export const getShipmentExport = async (params) => {
    const response = await get(`${API_URL}/shipments?${params}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const updateCollectionDeliveryShipment = async (payload) => {
    const response = await put(
        `${API_URL}/shipment/update-collection-delivery-address`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const resendNotification = async (payload) => {
    const response = await post(`${API_URL}/resend-notification`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};