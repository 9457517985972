export const roles = {
    AccountUser: [
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'address-books',
        },
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'sent-sms',
        },
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'sent-whatsapp',
        },
    ],
    AccountUserNonBilling: [
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'address-books',
        },
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'sent-sms',
        },
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'sent-whatsapp',
        },
    ],
    AccountSuperUser: [
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'address-books',
        },
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'sent-sms',
        },
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'sent-whatsapp',
        },
    ],
    SuperAdmin: [
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'address-books',
        },
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'sent-sms',
        },
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'sent-whatsapp',
        },
        {
            action: ['read', 'create', 'edit', 'list'],
            resource: 'payment-references',
        },
    ],
};
