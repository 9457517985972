import { API_URL } from 'config.js';
import { get } from './helpers';

export const getGeneralDataFilters = async () => {
    const response = await get(`${API_URL}/general-data`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getTags = async () => {
    const response = await get(`${API_URL}/tags`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getAllRateGroups = async () => {
    const response = await get(`${API_URL}/rates/groups/current`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getRateGroupsBasedOnServiceCode = async (code) => {
    const response = await get(
        `${API_URL}/rates/groups/based/service-code?code=${code}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getAllServices = async () => {
    const response = await get(`${API_URL}/rates/services`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getServicesByGroup = async (params) => {
    const response = await get(`${API_URL}/rates/services-by-group?${params}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getServiceByGroup = async (params) => {
    const response = await get(`${API_URL}/rates/service-by-group?${params}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};
