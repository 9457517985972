import jwt_decode from 'jwt-decode';

const _headers = (options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
        options.headers.set('Content-Type', 'application/json');
    }

    const token = localStorage.getItem('token');
    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
        options.headers.set("requested-from", "portal");
    }

    return options;
};

export const _decodeData = (data) => {
    const { apimode, ret } = data;
    let retData;
    if (apimode === 'LIVE') {
        retData = jwt_decode(ret);
    } else {
        retData = ret;
    }
    return retData;
};

export const post = async (url, payload) => {
    const { headers } = await _headers();
    return await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
    });
};

export const patch = async (url, payload) => {
    const { headers } = await _headers();
    return await fetch(url, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(payload),
    });
};

export const put = async (url, payload) => {
    const { headers } = await _headers();
    return await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(payload),
    });
};

export const get = async (url) => {
    const { headers } = await _headers();
    return await fetch(url, {
        method: 'GET',
        headers: headers,
    });
};

export const postfile = async (url, payload) => {
    const token = localStorage.getItem('token');
    return await fetch(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
        },
        body: payload,
    });
};

export const upload = async (url, formData) => {
    const token = localStorage.getItem('token');
    return await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getStatusRange = (status, start_status) => {
    const status_list = [
        'submitted',
        'deposit-pending',
        'customer-deposited',
        'collection-assigned',
        'collection-unassigned',
        'collection-rejected',
        'collection-exception',
        'collection-failed-attempt',
        'collected',
        'at-hub',
        'in-transit',
        'returned-to-hub',
        'at-destination-hub',
        'delivery-unassigned',
        'delivery-assigned',
        'out-for-delivery',
        'courier-deposited',
        'delivery-rejected',
        'delivery-exception',
        'in-locker',
        'customer-collected',
        'delivered',
    ];

    // Get the indices of start_status and status
    const startIndex = status_list.indexOf(start_status);
    const statusIndex = status_list.indexOf(status);

    // Check if the status is between start_status and the last index of status_list
    const isInRange = startIndex !== -1 && statusIndex !== -1 && statusIndex >= startIndex;

    return isInRange;
};